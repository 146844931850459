import React, {Fragment, useEffect, useRef, useState} from "react";
import {MainHeader, NavLink} from "../components/header/MainHeader";
import {FooterNav} from "../components/nav/FooterNav";
import {
    ApplicationModal,
    ContactModal,
    DriveGallery,
    FloorplanSectionSkeleton,
    FloorplanShortTerm,
    Footer,
    getShortTermFloorplansByPropertyId,
    PropertyLocation,
    ShortTermFloorplansSection,
    ShortTermPricingCard,
    SummerHouseFeatures,
    TeamSection,
    trackContactClicked,
} from "@renaissancerentals/renaissance-component";
import {HeroSection} from "../components/hero/HeroSection";
import {ContactHeader} from "../components/header/ContactHeader";
import {Helmet} from "react-helmet-async";
import {propertyId, shortTermPropertyId} from "../services/DataService";
import {NavItem} from "../components/nav/NavProps";
import {PageProps} from "../data/PageProps";
import {Li, Ul} from "@contentmunch/muncher-ui";

export const ShortTerm: React.FC<PageProps> = ({property, isLoading, contact}) => {

    const [showContactModal, setShowContactModal] = useState(false);
    const [showApplicationModal, setShowApplicationModal] = useState(false);
    const [isShortTermLoading, setIsShortTermLoading] = useState(true);
    const [floorplanShortTerms, setFloorplanShortTerms] = useState<FloorplanShortTerm[]>([]);


    const mapRef = useRef<HTMLDivElement>(null);
    const featureSectionRef = useRef<HTMLDivElement>(null);
    const amenitiesSectionRef = useRef<HTMLDivElement>(null);
    const primaryNavs: NavItem[] = [
        {
            anchor: "#glance",
            text: "at a glance",
            handleRef: () => {
                handleRefToFeatures();
            }
        },
        {
            anchor: "#amenities",
            text: "amenities",
            handleRef: () => {
                handleRefToAmenities();
            }
        }
    ];
    const secondaryNavs: NavItem[] = [

        {
            anchor: "#apply",
            text: "apply",
            handleRef: () => {
                setShowApplicationModal(true);
            }
        },
        {
            anchor: "#location",
            text: "location",
            handleRef: () => {
                handleRefToMap()
            }
        },
        {
            anchor: "#contact",
            text: "contact",
            handleRef: () => {
                setShowContactModal(true);
                trackContactClicked("summer-house-short-term");
            }
        }
    ];
    const leftLink: NavLink = {
        text: "Looking for Long Term apartments?",
        to: "/long-term"
    };

    const handleRefToFeatures = () => {
        featureSectionRef.current?.scrollIntoView({
            behavior: "smooth",
            block: "start"
        });
    };
    const handleRefToAmenities = () => {
        amenitiesSectionRef.current?.scrollIntoView({
            behavior: "smooth",
            block: "start"
        });
    };
    const handleRefToMap = () => {
        mapRef.current?.scrollIntoView({
            behavior: "smooth",
            block: "center"
        });
    };

    useEffect(() => {
        getShortTermFloorplansByPropertyId(shortTermPropertyId).then(value => {
            setFloorplanShortTerms(value);
        }).catch((error) => {
            console.log(error);
        }).finally(() => {
            setIsShortTermLoading(false);
        });
    }, []);
    return (
        <Fragment>
            <Helmet>
                <title>Short term & corporate housing Apartments Bloomington Indiana</title>
                <meta name="description"
                      content="SummerHouse at Indiana 1 & 2 bedroom, fully furnished & equipped, short term apartment rentals 1.5 miles east of IU campus in Bloomington, IN"/>
            </Helmet>
            <ContactModal showContactModal={showContactModal}
                          contactModalCloseHandler={() => setShowContactModal(false)}
                          conversionTrackingId1={property.conversionTrackingId1}
                          conversionTrackingId2={property.conversionTrackingId2}
                          to={property.email}
                          contactNumber={property.phone}
                          subject={"Message from " + property.name}
                          propertyId="summer-house-short-term"
            />
            <ApplicationModal showApplicationModal={showApplicationModal}
                              applicationModalCloseHandler={() => setShowApplicationModal(false)}
                              propertyName={property.name}
                              propertyEmail={property.email}
                              contactClickHandler={() => {
                                  setShowApplicationModal(false);
                                  setShowContactModal(true);
                                  trackContactClicked("summer-house-short-term");
                              }}/>

            {/*<BannerSection/>*/}
            <ContactHeader contact={contact}/>
            <MainHeader primaryNavs={primaryNavs} secondaryNavs={secondaryNavs} leftLink={leftLink} contact={contact}
                        contactClickHandler={() => {
                            setShowContactModal(true);
                            trackContactClicked("summer-house-short-term");
                        }}/>
            {isLoading ? "" : <main>
                <HeroSection
                    coverVideo={property.coverVideo}
                    videoUrl="https://www.googleapis.com/drive/v3/files/1q3uSFbf9Kxtc9CUk51kre0isUZIT1kUX?alt=media&key=AIzaSyAdG4u5YD2CZvQTv_hRtaKrmSNWZkY30oU"/>
                <div ref={featureSectionRef} id="glance" className="reference">
                </div>
                <SummerHouseFeatures/>

                <div ref={amenitiesSectionRef} id="amenities" className="reference">
                </div>
                <section className="section-amenities">
                    <div className="container">
                        <h2 className="heading"><span className="emphasized">Community Amenities</span></h2>
                        <Ul>
                            {property.amenities.map(amenity =>
                                <Li key={"amenity-" + amenity.id}
                                    isFeatured={amenity.featured}>{amenity.name}</Li>
                            )}
                        </Ul>
                    </div>
                </section>
                <ShortTermPricingCard floorplans={floorplanShortTerms.sort((a, b) => a.bedroom - b.bedroom)}/>

                <div className="container">
                    <h2 className="heading"><span className="emphasized">Community Photos</span></h2>
                    <DriveGallery
                        propertyId={shortTermPropertyId}
                        driveId={property.photosFolderId}
                        initialSize={6}
                        type="simple"
                        showName={false}
                    />
                </div>

                {isShortTermLoading ?
                    <FloorplanSectionSkeleton/> :
                    <ShortTermFloorplansSection floorplans={floorplanShortTerms} propertyId={shortTermPropertyId}
                                                title="Short Term FloorPlans"/>
                }

                <div ref={mapRef} id="location" className="reference">
                </div>
                <div className="container">
                    <h2 className="heading"><span className="emphasized">Location</span></h2>
                </div>
                <PropertyLocation property={property} isLoading={isLoading} handleRefToContact={() => {
                    setShowContactModal(true);
                    trackContactClicked("summer-house-short-term");
                }}/>

                <TeamSection isLoading={isLoading} teamMembers={property.teamMembers} propertyId={propertyId}/>

            </main>}

            <Footer nav={<FooterNav handleRefToApply={() => setShowApplicationModal(true)}
                                    handleRefToMap={handleRefToMap} contact={contact}
                                    handleRefToContact={() => {
                                        setShowContactModal(true);
                                        trackContactClicked("summer-house-short-term");
                                    }}/>}/>
        </Fragment>
    );
};
