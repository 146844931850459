import React from 'react';
import './assets/HeroSection.scss';

import {PropertyVideo, Video} from "@renaissancerentals/renaissance-component";

export const HeroSection: React.FC<HeroSectionProps> = ({videoUrl, coverVideo}) => {
    return (
        <section className="section-hero">
            {coverVideo ? <PropertyVideo coverVideo={coverVideo}/> : <Video url={videoUrl}/>}

        </section>
    );
}

export interface HeroSectionProps {
    videoUrl: string;
    coverVideo: string | null;
}
